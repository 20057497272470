import { styled, css } from '@creditas/stylitas'
import { typography } from '@creditas-lab/style-utilities'
import { media } from '@creditas-ui/utilities'
import { Typography } from '@creditas/typography'

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    align-content: stratch;
    background: ${theme.colorNeutral10};
    margin: 48px auto;
    min-height: 484px;
    flex-direction: column-reverse;

    width: 100%;
    max-width: 318px;

    ${media.up('5xl')} {
      flex-direction: row;
      max-width: 392px;
      margin: 64px auto;
    }

    align-items: center;
  `,
)

const Content = styled.div`
  flex: 1;
  padding: 24px 24px 32px;

  ${media.up('5xl')} {
    padding: 48px 48px 56px;
  }

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    margin-top: 48px;
  }
`

const Header = styled.div`
  width: 100%;
`

const Title = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colorNeutral90};
    ${typography.desktop.titleLG300(theme)}

    strong {
      font-weight: 700;
    }
  `,
)

const Description = styled(Typography)(
  ({ theme }) => css`
    margin-top: 16px;
    ${typography.desktop.paragraphSM400(theme)}
  `,
)

export { Container, Content, Header, Title, Description }

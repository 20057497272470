import { styled, css } from '@creditas/stylitas'
import { typography } from '@creditas-lab/style-utilities'
import { media } from '@creditas-ui/utilities'
import { Typography } from '@creditas/typography'
import { Container, Grid as CreditasGrid, GridItem } from '@creditas/layout'
import { LazyImage } from 'components/LazyImage'

const Wrapper = styled(Container)``

const Header = styled(GridItem)`
  margin-top: 24px;
  margin-bottom: 56px;

  ${media.up('5xl')} {
    margin-top: 58px;
    margin-bottom: 57px;
  }

  @media (min-width: 1400px) {
    margin-top: 82px;
    margin-bottom: 114px;
  }
`

const Title = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colorNeutral90};
    margin-bottom: 16px;
    ${typography.mobile.titleXL300(theme)}

    ${media.up('5xl')} {
      margin-bottom: 24px;
      ${typography.desktop.titleLG300(theme)}
      font-size: 48px;
    }

    strong {
      font-weight: 700;
    }
  `,
)

const Description = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colorNeutral60};
    ${typography.mobile.paragraphLG400(theme)}

    ${media.up('5xl')} {
      ${typography.desktop.paragraphLG300(theme)}
    }
  `,
)

const Image = styled(LazyImage)`
  margin-top: -214px;
  margin-left: -16px;
  margin-right: -16px;
  z-index: -1;

  @media (min-width: 728px) {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
`

const CardsShelf = styled.div(
  () => css`
    display: flex;
    justify-content: flex-start;
    overflow: auto;
    width: auto;
    margin-left: -16px;
    margin-right: -16px;
    padding: 0 0 40px;

    @media (min-width: 728px) {
      overflow: auto;
      padding: 144px 0;
      margin-left: -115px;
      padding-left: 100px;
      width: calc(100vw + 100px);
    }

    &:after,
    &:before {
      content: '';
      display: block;
      min-width: 1px;
    }

    &:before {
      margin-right: 8px;
      margin-left: -1px;
    }

    &:after {
      margin-left: 8px;
      margin-right: -1px;
    }

    & > * {
      width: 236px;
      min-width: 236px;
      margin: 0 8px;

      ${media.up('5xl')} {
        width: 288px;
        min-width: 288px;
      }
    }
  `,
)

const gridOptions = {
  gridPadding: ['0'],
  gridColumns: {
    xs: 'repeat(4, 1fr)',
    md: 'repeat(12, 1fr)',
  },
  gridRows: {
    xs: 'max-content',
    md: 'max-content',
  },
  gridAreas: {
    xs: `
      "${'header '.repeat(4)}"
      "${'children '.repeat(4)}"
      "${'image '.repeat(4)}"
    `,
    md: `
      ". ${'header '.repeat(5)} . ${'image '.repeat(5)}"
      "${'children '.repeat(7)} ${'image '.repeat(5)}"
    `,
  },
}

const Grid = styled(CreditasGrid)`
  @media(min-width: 728px) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: ". ${'header '.repeat(5)} . ${'image '.repeat(5)}"
                         "${'children '.repeat(7)} ${'image '.repeat(5)}"
  }
`

export {
  Wrapper,
  Header,
  Title,
  Description,
  Image,
  CardsShelf,
  gridOptions,
  Grid,
}

import { styled, css } from '@creditas/stylitas'
import { typography } from '@creditas-lab/style-utilities'
import { media } from '@creditas-ui/utilities'
import { Typography } from '@creditas/typography'

const Wrapper = styled.p(
  ({ theme }) => css`
    text-decoration: none;
    background-color: ${theme.colorBackgroundNeutral10};
    margin-bottom: 32px;

    ${media.up('5xl')} {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  `,
)

const Title = styled(Typography)(
  ({ theme }) => css`
    padding-bottom: 8px;
    color: ${theme.colorBrandPrimaryDefault};
    ${typography.mobile.titleLG300(theme)}
    font-weight: bold;

    ${media.up('5xl')} {
      ${typography.desktop.titleMD300(theme)}
    }
  `,
)

const Description = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colorNeutral80};
    ${typography.desktop.paragraphMD400(theme)};
  `,
)

export { Wrapper, Title, Description }

import { styled, css } from '@creditas/stylitas'
import { typography } from '@creditas-lab/style-utilities'
import { media } from '@creditas-ui/utilities'
import { Container as CreditasContainer, Grid } from '@creditas/layout'
import { Typography } from '@creditas/typography'

const Wrapper = styled.section(
  ({ theme }) => css`
    padding: 48px 0;
    background-color: ${theme.colorBackgroundNeutral10};

    ${media.up('5xl')} {
      padding: 64px 0;
    }
  `,
)

const Container = styled(CreditasContainer)``

const Title = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: 8px;
    color: ${theme.colorNeutral90};
    ${typography.mobile.titleXL300(theme)}

    ${media.up('5xl')} {
      ${typography.desktop.titleLG300(theme)}
    }
  `,
)

const SubTitle = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colorNeutral80};
    ${typography.desktop.paragraphMD400(theme)};
  `,
)

const Posts = styled(Grid)`
  margin-top: 24px;
`

const postsGridOptions = {
  gridPadding: ['0'],
  gridColumns: {
    xs: 'repeat(auto-fit, minmax(288px, 1fr))',
    md: 'repeat(4, 1fr)',
  },
}

export { Wrapper, Container, Title, SubTitle, Posts, postsGridOptions }
